// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAVyVXNSlcyZDNB7JhmTW7rCU-sPM9Yaug",
  authDomain: "danjiri-gps-firebase.firebaseapp.com",
  projectId: "danjiri-gps-firebase",
  storageBucket: "danjiri-gps-firebase.appspot.com",
  messagingSenderId: "776657883524",
  appId: "1:776657883524:web:f0421d47b3edeeab048dd1",
  measurementId: "G-MT1BWVR8CL"
};

const initializeFirebase = () => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
}

export { initializeFirebase };