import React from 'react';
import '../common/App.css';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, Outlet } from 'react-router-dom';
import { MenuItems } from '../components/MenuItems';
import { CssBaseline } from '@mui/material';
import { useWindowSize } from '../common/useWindowSize';



function App() {
  const menuItems = MenuItems
  const drawerWidth = '180px'

  const theme = useTheme()
  const isPC = useMediaQuery(theme.breakpoints.up('md'));
  const [_, height] = useWindowSize()

  const [navValue, setNavValue] = React.useState(0)

  const list = () => (
    <Box
      role="presentation"
    >
      <List>
        {menuItems.map((item) => (
          <ListItem key={item.name} component={Link} to={item.link} disablePadding button>
            <ListItemButton>
              <ListItemIcon>
                { item.icon }
              </ListItemIcon>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const menuComponent = () => {
    if (isPC) {
      return (
        <>
          <Box
            component="nav"
            sx={{
              width: drawerWidth,
              height: '100vh',
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
              },
            }}    
          >
            <Drawer
              variant="permanent"
              anchor="left"
            >
              { list() }
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: 'background.default', width: `calc(100% - ${drawerWidth})`}}
          >
            <Outlet />
          </Box>
        </>
      )
    } else {
      return (
        <>
          <Box
            component="main" 
            sx={{
              width: '100vw',
              height: `calc(${height}px - 56px)`,
            }}
          >
            <Outlet />
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
              <Box
                component="nav"
              >
                <BottomNavigation
                  showLabels
                  value={navValue}
                  onChange={(event, newValue) => {
                    setNavValue(newValue);
                  }}
                >
                  {
                    menuItems.map(item => (
                      <BottomNavigationAction key={item.name} label={item.name} icon={item.icon} component={Link} to={item.link} />
                    ))
                  }
                </BottomNavigation>
              </Box>
            </Paper>
          </Box>
        </>
      )
    }
  }

  return (
    <div className="App">
      <Box sx={{ display: 'flex' }}>
        { menuComponent() }
      </Box>
    </div>
  );
}

export default App;
