import React, { useEffect, useMemo, useState, useRef } from 'react';
import Map, {Marker, Popup, Source, Layer, LayerProps, NavigationControl, GeolocateControl, MapRef, Anchor } from 'react-map-gl';
import maplibregl, { Offset } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import Api from '../api/Api';
import Pin, {  HomePin, HonbuPin, NavigationPin } from '../components/Pin';
import { AxiosError, AxiosResponse } from 'axios';
import ReloadControl from '../components/ReloadControl';
import InfoGeoJson from '../common/info.json'
import StyleJson from '../common/style.json'

type DanjiriInfoApi = {
    lat: number;
    lng: number;
    Name: string;
    ID: number;
    dir: number;
    bat: number;
    distance: number;
    uid: string;
    datetime: string;
}

function LeafletContainer() {
    const [positions, setPosition] = useState<DanjiriInfoApi[]>([]);
    const home_positions: DanjiriInfoApi[] = [
        {
            lat: 34.489988714802074,
            lng: 135.395994159222,
            Name: "仲之町",
            ID: 0,
            bat: 0,
            dir: 0,
            distance: 0,
            uid: '0',
            datetime: "20221001"
        },
        {
            lat: 34.491684745938966,
            lng: 135.39260738420527,
            Name: "濱之町",
            ID: 1,
            bat: 0,
            dir: 0,
            distance: 0,
            uid: '1',
            datetime: "20221001"
        },
        {
            lat: 34.495115443966544,
            lng: 135.39205673847602,
            Name: "生帰",
            ID: 2,
            bat: 0,
            dir: 0,
            distance: 0,
            uid: '2',
            datetime: "20221001"
        },
        {
            lat: 34.49369016381264,
            lng: 135.39498172619321,
            Name: "道之町",
            ID: 3,
            bat: 0,
            dir: 0,
            distance: 0,
            uid: '3',
            datetime: "20221001"
        },

    ]

    const honbu_positions = [
        {
            lat: 34.48923437492385,
            lng: 135.39573872257492,
            Name: "本部",
            ID: 4,
            bat: 0,
            dir: 0,
            distance: 0,
            uid: '4',
            datetime: "20221001"
        }
    ]

    const key = "HNK8esWVWMnCD4tp3CDT"

    const [popupInfo, setPopupInfo] = useState<DanjiriInfoApi|null>(null);
    // const [geoJson, setGeoJson] = useState<any>(null);
    const mapRef = useRef<MapRef>(null);
    const information: any = InfoGeoJson

    const getLatestPos = () => {
        console.log("GET LATEST")
        Api.get('/position/tadaoka/latest').then((res: AxiosResponse<DanjiriInfoApi[]>) => {
            const pos: DanjiriInfoApi[] = res.data;
            setPosition(pos)
        }).catch((err: AxiosError) => {
            if (err.response?.status === 404) {
                // 何もない時はNot Found
                setPosition([])
            }
        })
    }

    // const fetchHistory = (uid: string) => {
    //     Api.get(`/position/${uid}/history`).then((res: AxiosResponse<any>) => {
    //         const geojson = res.data
    //         setGeoJson(geojson)
    //     })
    // }

    // const centerize = () => {
    //     if (positions.length < 1) return
    //     const latitudes = positions.map(value => value.lat)
    //     const longitudes = positions.map(value => value.lng)
    //     const max_lat = Math.max(...latitudes)
    //     const min_lat = Math.min(...latitudes)
    //     const max_lng = Math.max(...longitudes)
    //     const min_lng = Math.min(...longitudes)
    //     mapRef.current?.setCenter([(max_lng + min_lng) / 2, (max_lat + min_lat) / 2])
    // }

    useEffect(() => {
        getLatestPos()
        const intervalId = setInterval(() => {
            getLatestPos()
        }, 30 * 1000)
        return () => {
            clearInterval(intervalId)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('focus', getLatestPos, false)
    }, [])

    // useEffect(() => {
    //     centerize()
    // }, [positions])
    

    const onPinClick = (position: DanjiriInfoApi) => {
        // ピンを連続で押すとCloseと競合して正しく開かない問題を遅延で修正
        setTimeout(() => {
            // fetchHistory(position.uid)
            setPopupInfo(position)
        }, 50)
    }

    const onInfoClose = () => {
        setPopupInfo(null)
        // setGeoJson(null)
    }

    const createMarkers = useMemo(() => {
        return positions.map(val => {
            let pin: JSX.Element;
            let anchor: Anchor
            let offset: Offset
            if (val.distance <= 15) {
                pin = (
                    <Pin name={val.Name} size={32} onClick={() => onPinClick(val)} />
                )
                anchor = 'bottom'
                offset = [0, 0]
            } else {
                pin = <NavigationPin name={val.Name} dir={val.dir} size={28} onClick={() => onPinClick(val)} />
                anchor='center'
                offset = [0, -2]
            }
            return (
                <Marker latitude={val.lat} longitude={val.lng}
                    anchor={anchor}
                    offset={offset}
                    key={val.uid}
                > 
                    { pin }
                </Marker>
            )
        })
    }, [positions])

    const createHomeMarkers = useMemo(() => {
        return home_positions.map(val => {
            return (
                <Marker latitude={val.lat} longitude={val.lng}
                    anchor='bottom'
                    key={val.uid}
                > 
                    <HomePin name={val.Name} size={24} />
                </Marker>
            )
        })
    }, [home_positions])

    const createHonbuMarkers = useMemo(() => {
        return honbu_positions.map(val => {
            return (
                <Marker latitude={val.lat} longitude={val.lng}
                    anchor='bottom'
                    key={val.uid}
                > 
                    <HonbuPin name={val.Name} size={24} />
                </Marker>
            )
        })
    }, [honbu_positions])

    // const layerStyle: LayerProps = {
    //     id: 'history',
    //     type: 'line',
    //     paint: {
    //         'line-color': '#7bb7a3',
    //         'line-width': 3,
    //         'line-dasharray': [2, 1.75]
    //     }
    // };

    // const layerStylePoint: LayerProps = {
    //     id: 'history-point',
    //     type: 'circle',
    //     paint: {
    //         'circle-color': '#70b29a',
    //         'circle-stroke-color': '#eaf0ee',
    //         'circle-stroke-width': 2,
    //         'circle-radius': 7
    //     }
    // };

    const layerStylePolygon: LayerProps = {
        id: "information",
        type: "fill",
        paint: {
            "fill-color": "#696969",
            "fill-opacity": 0.5,
        }
    }

    const style: any = StyleJson

    return (
        <Map
            mapLib={maplibregl}
            ref={mapRef}
            // mapStyle={`https://api.maptiler.com/maps/jp-mierune-streets/style.json?key=${key}`}
            mapStyle={style}
            style={{width: '100%', height: '100%'}}
            initialViewState={{
                longitude: 135.3926221,
                latitude: 34.4940299,
                zoom: 15
            }}
            reuseMaps
        >
            <NavigationControl />
            <GeolocateControl />
            <ReloadControl onClick={() => getLatestPos()}/>
            <Source id='information' type='geojson' data={information}>
                <Layer {...layerStylePolygon} />
            </Source>
            { createHomeMarkers }
            { createHonbuMarkers }
            { createMarkers }
            {popupInfo && (
                <Popup
                    anchor='bottom'
                    latitude={popupInfo.lat}
                    longitude={popupInfo.lng}
                    closeOnClick={true}
                    offset={28}
                    onClose={() => onInfoClose()}
                >
                    <div>
                        {popupInfo.Name}
                        <br></br>
                        {popupInfo.datetime}
                    </div>
                </Popup>
            )}

            {/* {geoJson && (
            <Source id="history-data" type="geojson" data={geoJson}>
                <Layer {...layerStyle} />
                <Layer {...layerStylePoint} />
            </Source>
            )} */}

        </Map>
    )
}

export default LeafletContainer;