import Box from "@mui/material/Box"


const PrivacyPolicy = () => {
    return (<Box sx={{ width: '100%', height: '100%', maxWidth: '1024px' }}>
        <article className='markdown-body'>
            <h1 id="title">プライバシーポリシー</h1>
            <h2>だんじりGPSアプリについて</h2>
            <p>
                本アプリケーションは開発者(Masahiro Konishi)が無料で提供しているアプリとなります。
            </p>
            <h2>広告について</h2>
            <p>
                本アプリでは、広告配信ツールとしてAdMob(Google Inc.)を使用しており、AdMobがご利用者の情報を自動取得する場合がございます。取得する情報、利用目的、第三者への提供等につきましては、以下の広告配信事業者のアプリケーション・プライバシーポリシーのリンクよりご確認ください。
            </p>
            <p>
                Google 広告に関するポリシー<br/>
                <a href="https://policies.google.com/technologies/ads?hl=ja" target="_blank" rel="noopener">https://policies.google.com/technologies/ads?hl=ja</a>
            </p>
            <h2>利用状況解析について</h2>
            <p>
                本アプリでは、今後の開発の参考とするため、アプリの利用状況データを収集するツールとしてFirebase(Google Inc.)を使用しており、Firebaseがご利用者の情報を自動取得する場合がございます。取得する情報、利用目的、第三者への提供等につきましては、以下のGoogleプライバシーポリシーのリンクよりご確認ください。
            </p>
            <p>
                Google プライバシーポリシー <br/>
                <a href="https://policies.google.com/privacy?hl=ja" target="_blank" rel="noopener">https://policies.google.com/privacy?hl=ja</a>
            </p>
            <h2>問い合わせ先について</h2>
            <p>
                個人情報保護方針についてご質問やご提案がございましたら、support@danjirigps.com までご連絡ください。
            </p>
            <p>
                アプリ提供者: Masahiro Konishi
            </p>
        </article>
    </Box>)
}

export { PrivacyPolicy }
