import PlaceIcon from '@mui/icons-material/Place';
import InfoIcon from '@mui/icons-material/Info';
import { ReactElement } from 'react';

type MenuItem = {
    name: string,
    link: string,
    icon: ReactElement
}

const MenuItems: MenuItem[] = [
    {
        name: "MAP",
        link: "/",
        icon: <PlaceIcon />
    },
    {
        name: "INFO",
        link: "/info",
        icon: <InfoIcon />
    }
]

export type { MenuItem }
export { MenuItems }