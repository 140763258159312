import * as React from 'react';

const ICON = `M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z`;

const GARAGE_ICON = "M19,20H17V11H7V20H5V9L12,5L19,9V20M8,12H16V14H8V12Z"

const NAVIGATION = "M12,2L4.5,20.29L5.21,21L12,18L18.79,21L19.5,20.29L12,2Z";

const HOME = "M12.36,6L12.76,8H18V14H14.64L14.24,12H7V6H12.36M14,4H5V21H7V14H12.6L13,16H20V6H14.4"

const CANCEL = "M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22 2 17.5 2 12 6.5 2 12 2M12 4C10.1 4 8.4 4.6 7.1 5.7L18.3 16.9C19.3 15.5 20 13.8 20 12C20 7.6 16.4 4 12 4M16.9 18.3L5.7 7.1C4.6 8.4 4 10.1 4 12C4 16.4 7.6 20 12 20C13.9 20 15.6 19.4 16.9 18.3Z"

const pinStyle = (dir: number) => {
  return {
    cursor: 'pointer',
    fill: '#d00',
    stroke: 'none',
    transform: `rotateZ(${dir}deg)`,
  }
};

const homePinStyle = {
  cursor: 'default',
  fill: '#000',
  stroke: 'none'
};

const cancelPinStyle = {
  cursor: 'default',
  fill: '#f10e0e',
}


const roundedLabelStyle: React.CSSProperties = {
  background: 'white',
  padding: '0.25em 0.4em',
  fontSize: '0.75rem',
  borderRadius: '1em',
  border: '1px solic lightgray',
  left: '0px'
}

const roundedLabelStyleHome: React.CSSProperties = {
  background: 'white',
  padding: '0.15em 0.4em',
  fontSize: '0.65rem',
  borderRadius: '1em',
  border: '1px solic lightgray',
  position: 'relative',

}

function Pin({name, size = 24, onClick}: {name: string; size?: number; onClick?: () => void}) {
  return (
    <div className="pin">
      <div style={roundedLabelStyle}>{name}</div>
      <svg height={size} viewBox="0 0 24 24" style={pinStyle(0)} onClick={onClick}>
        <path d={ICON} />
      </svg>
    </div>
  );
}

function HomePin({name, size = 20}: {name: string, size?: number}) {
  return (
    <div className="pin">
      <div style={roundedLabelStyleHome}>{name}</div>
      <svg height={size} viewBox="0 0 24 24" style={homePinStyle}>
        <path d={GARAGE_ICON} />
      </svg>
    </div>
  )
}

function NavigationPinFunc({name, dir, size = 20, onClick}: {name: string, dir: number, size?: number, onClick?: () => void}) {
  return (
    <div className="pin">
      <div style={roundedLabelStyle}>{name}</div>
      <svg height={size} viewBox="0 0 24 24" style={pinStyle(dir)} onClick={onClick}>
        <path d={NAVIGATION} />
      </svg>
    </div>
  );
}

function HonbuPinFunc({name, size = 24}: {name: string, size?: number}) {
  return (
    <div className="pin">
      <div style={roundedLabelStyleHome}>{name}</div>
      <svg height={size} viewBox="0 0 24 24" style={homePinStyle}>
        <path d={HOME} />
      </svg>
    </div>
  )
}

function CancelPinFunc() {
  return (
    <svg height={24} viewBox="0 0 24 24" style={cancelPinStyle}>
      <path d={CANCEL} />
    </svg>
  )
}

const NavigationPin = React.memo(NavigationPinFunc)
const HonbuPin = React.memo(HonbuPinFunc)
const CancelPin = React.memo(CancelPinFunc)
export { HomePin, NavigationPin, HonbuPin, CancelPin };
export default React.memo(Pin);