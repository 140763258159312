import React from 'react';
import ReactDOM from 'react-dom';
import './common/index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import LeafletContainer from './pages/LeafletContainer';
import { Information } from './pages/Information';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { initializeFirebase } from './firebase';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <LeafletContainer />
      },
      {
        path: "/info",
        element: <Information />
      },
      {
        path: "/privacy",
        element: <PrivacyPolicy />
      }
    ]
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById('root')
);

initializeFirebase();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

