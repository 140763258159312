import {useControl} from 'react-map-gl';

import type {MapRef, ControlPosition, IControl} from 'react-map-gl';
import type { Map } from 'mapbox-gl'


type ReloadControlProps = {
    position?: ControlPosition;
    onClick: (e?: MouseEvent) => void;
  
    onCreate?: (evt: {features: object[]}) => void;
    onUpdate?: (evt: {features: object[]; action: string}) => void;
    onDelete?: (evt: {features: object[]}) => void;
};

class ReloadControlType implements IControl {
    container: HTMLDivElement;
    disabled: boolean = false;
    onClick: (e?: MouseEvent) => void;

    constructor(props: ReloadControlProps) {
        this.container = document.createElement('div')
        this.onClick = props.onClick
    }
    
    onAdd(map: Map): HTMLElement {
        const homeButton = document.createElement('button');
        homeButton.className = "maplibregl-ctrl-geolocate mapboxgl-ctrl-geolocate"
        homeButton.innerHTML = '<span class="refresh-icon"></span>';
        homeButton.addEventListener('click', (e) => {
            if (this.disabled) return;
            this.disabled = true
            homeButton.disabled = true
            this.onClick(e)
            setTimeout(() => {
                homeButton.disabled = false
                this.disabled = false
            }, 5000);
        });

        this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
        this.container.appendChild(homeButton);

        return this.container;
    }
    onRemove(map: Map): void {
        this.container.parentNode?.removeChild(this.container);
    }
    // getDefaultPosition?: (() => ControlPosition) | undefined;
}

export default function ReloadControl(props: ReloadControlProps) {
    useControl<ReloadControlType>(
        () => new ReloadControlType(props),
        { position: "top-right" },
    )
    return null
}