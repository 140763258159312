import Box from '@mui/material/Box'
import '../common/github-markdown-light.css'
import Pin, { HomePin, NavigationPin } from '../components/Pin'
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';
import Stack from '@mui/material/Stack'

const shareAPI = async () => {
    try {
        await navigator.share({ title: document.title, url: "https://tadaoka.danjirigps.com" });
    } catch (error) {
        console.error(error);
    }
}

const Information = () => {
    return (
        <Box sx={{ width: '100%', height: '100%', maxWidth: '1024px' }}>
            <ins className="adsbygoogle"
                style={{display: "block"}}
                data-ad-client="ca-pub-8451292507408110"
                data-ad-slot="1977937903"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
            <article className='markdown-body'>
                <h1 id="title">忠岡だんじりGPS</h1>
                <p>だんじりの位置情報をリアルタイムで表示。だんじりを探し回る必要なし！</p>
                <Button variant="contained" startIcon={<ShareIcon />} sx={{mb: 2}} onClick={shareAPI}>
                    このページを共有する
                </Button>
                <h3>アプリ版</h3>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Box sx={{maxWidth: '250px', maxHeight: '90px'}}>
                        <a href="https://apps.apple.com/jp/app/%E5%BF%A0%E5%B2%A1%E3%81%A0%E3%82%93%E3%81%98%E3%82%8Agps/id6464160534?itsct=apps_box_badge&amp;itscg=30200" style={{display: "inline-block", overflow: "hidden", borderRadius: "13px", width: "250px", height: "83px"}}>
                            <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/ja-jp?size=250x83&amp;releaseDate=1694476800" alt="Download on the App Store" style={{borderRadius: "13px", width: "250px", height: "83px"}}/>
                        </a>
                    </Box>
                    <Box sx={{maxWidth: '250px', maxHeight: '90px'}}>
                        <a href='https://play.google.com/store/apps/details?id=com.danjirigps.danjiri_gps&hl=ja&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                            <img alt='Google Play で手に入れよう' src='https://play.google.com/intl/ja/badges/static/images/badges/ja_badge_web_generic.png'/>
                        </a>
                    </Box>
                </Stack>
                <div>

 
                </div>

                <p><small>※サービスは試験的に提供されており、予告なく終了する場合があります。<br />
                    ※GPSの測定誤差により実際の位置と10m程度ずれている場合があります。</small></p>
                <h2>使い方</h2>
                <ol>
                    <li>MAPを表示</li>
                    <span>それぞれのだんじりの現在位置は<strong>１分おきに更新</strong>され、<strong>自動で</strong>現在位置の表示が更新されます。</span>
                </ol>
                <p>今後も簡単にアクセスするためにブックマークに追加またはホーム画面へ追加を行うことをお勧めします。</p>
                <h3>iPhone(iOS)からご利用の方</h3>
                <p>アドレスバー左にある「ぁあ」をクリックし「ツールバーを非表示」を選択することでアドレスバーを非表示にできます。またはホーム画面に追加を行い、追加されたアイコンから起動すると常にアドレスバーが非表示になります。
                </p>
                <h3>アイコンの説明</h3>
                <ul style={{display: 'flex',flexDirection: 'column', alignItems: 'start'}} >
                    <li>矢印：移動中</li>
                    <NavigationPin name='' dir={90} />
                    <li>ピン：停止中</li>
                    <Pin name='' />
                    <li>家：だんじり小屋の位置</li>
                    <HomePin name='' />
                    <li>黒塗りの道：観覧者立ち入り禁止区域</li>
                    <small>※忠岡神社前は宮入り時のみ</small>
                </ul>
                <h3>地図の操作</h3>
                <ul>
                    <li>拡大縮小</li>
                    <span>スマートフォンの場合は2本指でピンチイン/ピンチアウトを行う、または右上のアイコンの +, - より調整できます。</span>
                    <li>現在位置</li>
                    <span>右上のアイコンの<GpsFixedIcon />をクリックし、位置情報の取得を許可してください。自分自身の現在位置が青色の丸で表示されます。</span>
                    <li>更新</li>
                    <span>右上のアイコンの<RefreshIcon />をクリックすると最新のだんじりの位置情報が強制的に取得されます。</span>
                </ul>
                <h3>詳細情報</h3>
                <p>だんじりの現在位置をクリックすると、その位置が取得された時間が表示されます。</p>
                <h2>リンク</h2>
                <a href='https://www.town.tadaoka.osaka.jp/'>忠岡町ホームページ</a><br/>
                <a href='https://www.tadaoka.or.jp/kanko/danjiri.html'>忠岡だんじり紹介</a><br/>
                <a href='https://forms.gle/QMP2ttfy1pz6CZSh6'>ご意見フォーム</a>
                <br />
                <br />
                <br />
                <br />
            </article>
        </Box>
    )
}

export { Information }